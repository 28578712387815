import * as React from 'react';

import { useTransition } from 'react-spring';

import {
  HomeImpact,
  HomeImpactTitle,
  HomeImpactContainer,
  HomeImpactCol,
  HomeImpactSlider,
  HomeImpactSlide,
  HomeImpactSlideImage,
  HomeImpactSectionTitle,
  HomeImpactTag,
  HomeImpactFeatureTitle,
  HomeImpactFeatureControls,
  HomeImpactFeatureNextContainer,
  HomeImpactFeatureNextArrow,
  HomeImpactFeatureNext,
  HomeImpactFeatureStep,
  HomeImpactSectionContainer,
  HomeImpactSectionCol,
  Link,
  HomeImpactSectionItem,
  HomeImpactSectionItemImg,
  HomeImpactSectionItemTag,
  HomeImpactSectionItemDesc,
  HomeImpactSectionGoTo,
  HomeImpactSectionGoToImpact,
  Description,
} from './styles';

import { CATEGORY_COLOR_BY_LABEL } from '../Impact/Categories/constants';
import { Logo, MenuButton } from '../../utils/theme';

import NextArrowImg from './next-arrow.png';

const Slide = ({ tag, src, title, description, post, ...props }) => {
  return (
    <Link href={post?.isExternalPost ? post.externalPostUrl : `/impact/${post?.id}`}>
      <HomeImpactSlide {...props}>
        {src ? <HomeImpactSlideImage src={src} /> : null}
        <HomeImpactTag
          style={{
            color: CATEGORY_COLOR_BY_LABEL[tag],
          }}
        >
          {tag}
        </HomeImpactTag>
        <HomeImpactFeatureTitle>{title}</HomeImpactFeatureTitle>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </HomeImpactSlide>
    </Link>
  );
};

const Slider = ({ posts }) => {
  const [index, setIndex] = React.useState(0);

  const onClick = () => setIndex((prevState) => (prevState < posts.length - 1 ? prevState + 1 : 0));

  const transitions = useTransition(index, (p) => p, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  });

  if (!posts.length) {
    return null;
  }

  const pages = (posts || []).map((post) => ({ style }) => (
    <Slide
      src={post?.headerImage?.url}
      style={style}
      tag={post?.label}
      title={post?.title}
      description={post?.description}
      post={post}
    />
  ));

  return (
    <>
      <HomeImpactSlider>
        {transitions.map(({ item, props, key }) => {
          const Page = pages[item];
          return <Page key={key} style={props} />;
        })}
      </HomeImpactSlider>
      <HomeImpactFeatureControls>
        <HomeImpactFeatureNextContainer onClick={onClick}>
          <HomeImpactFeatureNextArrow src={NextArrowImg} alt="Next" />
          {posts.length > 1 && <HomeImpactFeatureNext>Next</HomeImpactFeatureNext>}
        </HomeImpactFeatureNextContainer>
        <HomeImpactFeatureStep>
          {index + 1} of {posts.length}
        </HomeImpactFeatureStep>
      </HomeImpactFeatureControls>
    </>
  );
};

const HomeImpactComponent = (props) => {
  const posts = props.posts || [];
  const featuredPosts = posts.filter((post) => post.isFeatured);
  const nonFeaturedPosts = posts.filter((post) => !post.isFeatured);
  return (
    <HomeImpact>
      <Logo />
      <MenuButton menuIsShown={props.menuIsShown} />
      {!props.hideTitle && <HomeImpactTitle>IMPACT</HomeImpactTitle>}
      <HomeImpactContainer>
        <HomeImpactCol>
          <HomeImpactSectionTitle left>Featured Articles</HomeImpactSectionTitle>
          <Slider posts={featuredPosts} />
        </HomeImpactCol>
        <HomeImpactCol>
          <HomeImpactSectionTitle>This month</HomeImpactSectionTitle>
          <HomeImpactSectionContainer>
            <HomeImpactSectionCol>
              {nonFeaturedPosts[0] && (
                <Link
                  href={
                    nonFeaturedPosts[0]?.isExternalPost
                      ? nonFeaturedPosts[0]?.externalPostUrl
                      : `/impact/${nonFeaturedPosts[0]?.id}`
                  }
                  target={nonFeaturedPosts[0].isExternalPost ? '_blank' : undefined}
                >
                  <HomeImpactSectionItem>
                    <HomeImpactSectionItemImg
                      src={
                        nonFeaturedPosts[0].headerImage?.formats?.medium?.url ||
                        nonFeaturedPosts[0].headerImage?.url
                      }
                    >
                      <HomeImpactSectionItemTag
                        style={{
                          color: CATEGORY_COLOR_BY_LABEL[nonFeaturedPosts[0]?.categories[0]?.name],
                        }}
                      >
                        {nonFeaturedPosts[0]?.categories[0]?.name}
                      </HomeImpactSectionItemTag>
                    </HomeImpactSectionItemImg>
                    <HomeImpactSectionItemDesc>
                      {nonFeaturedPosts[0].title}
                    </HomeImpactSectionItemDesc>
                  </HomeImpactSectionItem>
                </Link>
              )}
              {nonFeaturedPosts[1] && (
                <Link
                  href={
                    nonFeaturedPosts[1]?.isExternalPost
                      ? nonFeaturedPosts[1]?.externalPostUrl
                      : `/impact/${nonFeaturedPosts[1]?.id}`
                  }
                  target={nonFeaturedPosts[1].isExternalPost ? '_blank' : undefined}
                >
                  <HomeImpactSectionItem>
                    <HomeImpactSectionItemImg
                      src={
                        nonFeaturedPosts[1].headerImage?.formats?.medium?.url ||
                        nonFeaturedPosts[1].headerImage?.url
                      }
                      tall
                    >
                      <HomeImpactSectionItemTag
                        style={{
                          color: CATEGORY_COLOR_BY_LABEL[nonFeaturedPosts[1]?.categories[0]?.name],
                        }}
                      >
                        {nonFeaturedPosts[1]?.categories[0]?.name}
                      </HomeImpactSectionItemTag>
                    </HomeImpactSectionItemImg>
                    <HomeImpactSectionItemDesc>
                      {nonFeaturedPosts[1].title}
                    </HomeImpactSectionItemDesc>
                  </HomeImpactSectionItem>
                </Link>
              )}
            </HomeImpactSectionCol>
            <HomeImpactSectionCol>
              {nonFeaturedPosts[2] && (
                <Link
                  href={
                    nonFeaturedPosts[2]?.isExternalPost
                      ? nonFeaturedPosts[2]?.externalPostUrl
                      : `/impact/${nonFeaturedPosts[2]?.id}`
                  }
                  target={nonFeaturedPosts[2].isExternalPost ? '_blank' : undefined}
                >
                  <HomeImpactSectionItem>
                    <HomeImpactSectionItemImg
                      src={
                        nonFeaturedPosts[2].headerImage?.formats?.medium?.url ||
                        nonFeaturedPosts[2].headerImage?.url
                      }
                    >
                      <HomeImpactSectionItemTag
                        style={{
                          color: CATEGORY_COLOR_BY_LABEL[nonFeaturedPosts[2]?.categories[0]?.name],
                        }}
                      >
                        {nonFeaturedPosts[2]?.categories[0]?.name}
                      </HomeImpactSectionItemTag>
                    </HomeImpactSectionItemImg>
                    <HomeImpactSectionItemDesc>
                      {nonFeaturedPosts[2].title}
                    </HomeImpactSectionItemDesc>
                  </HomeImpactSectionItem>
                </Link>
              )}
              {nonFeaturedPosts[3] && (
                <Link
                  href={
                    nonFeaturedPosts[3]?.isExternalPost
                      ? nonFeaturedPosts[3]?.externalPostUrl
                      : `/impact/${nonFeaturedPosts[3]?.id}`
                  }
                  target={nonFeaturedPosts[3].isExternalPost ? '_blank' : undefined}
                >
                  <HomeImpactSectionItem>
                    <HomeImpactSectionItemImg
                      src={
                        nonFeaturedPosts[3].headerImage?.formats?.medium?.url ||
                        nonFeaturedPosts[3].headerImage?.url
                      }
                      bigTall
                    >
                      <HomeImpactSectionItemTag
                        style={{
                          color: CATEGORY_COLOR_BY_LABEL[nonFeaturedPosts[3]?.categories[0]?.name],
                        }}
                      >
                        {nonFeaturedPosts[3]?.categories[0]?.name}
                      </HomeImpactSectionItemTag>
                    </HomeImpactSectionItemImg>
                    <HomeImpactSectionItemDesc>
                      {nonFeaturedPosts[3].title}
                    </HomeImpactSectionItemDesc>
                  </HomeImpactSectionItem>
                </Link>
              )}
            </HomeImpactSectionCol>
            <HomeImpactSectionCol>
              {nonFeaturedPosts[4] && (
                <Link
                  href={
                    nonFeaturedPosts[4]?.isExternalPost
                      ? nonFeaturedPosts[4]?.externalPostUrl
                      : `/impact/${nonFeaturedPosts[4]?.id}`
                  }
                  target={nonFeaturedPosts[4].isExternalPost ? '_blank' : undefined}
                >
                  <HomeImpactSectionItem>
                    <HomeImpactSectionItemImg
                      src={
                        nonFeaturedPosts[4].headerImage?.formats?.medium?.url ||
                        nonFeaturedPosts[4].headerImage?.url
                      }
                    >
                      <HomeImpactSectionItemTag
                        style={{
                          color: CATEGORY_COLOR_BY_LABEL[nonFeaturedPosts[4]?.categories[0]?.name],
                        }}
                      >
                        {nonFeaturedPosts[4]?.categories[0]?.name}
                      </HomeImpactSectionItemTag>
                    </HomeImpactSectionItemImg>
                    <HomeImpactSectionItemDesc>
                      {nonFeaturedPosts[4].title}
                    </HomeImpactSectionItemDesc>
                  </HomeImpactSectionItem>
                </Link>
              )}
              {nonFeaturedPosts[5] && (
                <Link
                  href={
                    nonFeaturedPosts[5]?.isExternalPost
                      ? nonFeaturedPosts[5]?.externalPostUrl
                      : `/impact/${nonFeaturedPosts[5]?.id}`
                  }
                  target={nonFeaturedPosts[5].isExternalPost ? '_blank' : undefined}
                >
                  <HomeImpactSectionItem>
                    <HomeImpactSectionItemImg
                      src={
                        nonFeaturedPosts[5].headerImage?.formats?.medium?.url ||
                        nonFeaturedPosts[5].headerImage?.url
                      }
                      tall
                    >
                      <HomeImpactSectionItemTag
                        style={{
                          color: CATEGORY_COLOR_BY_LABEL[nonFeaturedPosts[5]?.categories[0]?.name],
                        }}
                      >
                        {nonFeaturedPosts[5]?.categories[0]?.name}
                      </HomeImpactSectionItemTag>
                    </HomeImpactSectionItemImg>
                    <HomeImpactSectionItemDesc>
                      {nonFeaturedPosts[5].title}
                    </HomeImpactSectionItemDesc>
                  </HomeImpactSectionItem>
                </Link>
              )}
            </HomeImpactSectionCol>
            <HomeImpactSectionCol>
              <HomeImpactSectionGoTo href="/impact">
                <HomeImpactSectionGoToImpact>Go To Impact</HomeImpactSectionGoToImpact>
              </HomeImpactSectionGoTo>
            </HomeImpactSectionCol>
          </HomeImpactSectionContainer>
        </HomeImpactCol>
      </HomeImpactContainer>
    </HomeImpact>
  );
};

export default HomeImpactComponent;
