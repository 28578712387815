import styled from 'styled-components';
import { animated } from 'react-spring';

import { COLORS, BREAKPOINTS } from '../../utils/theme';

const SECTION_HEIGHT = 800;

export const HomeImpact = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
`;

export const HomeImpactTitle = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 30px 0;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const HomeImpactContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 60px 0 100px;
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: column;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 0;
  }
`;

export const HomeImpactCol = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const HomeImpactSlider = styled.div`
  display: flex;
`;

export const HomeImpactSlide = styled(animated.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 60px;
  height: ${SECTION_HEIGHT}px;
  max-width: 100%;
  will-change: transform, opacity;
  overflow-y: hidden;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 0 30px;
    height: ${SECTION_HEIGHT - 40}px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 0;
    height: 550px;
  }
`;

export const HomeImpactSlideImage = styled.img`
  height: 403px;
  margin-bottom: 50px;
  object-fit: cover;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    height: 260px;
    margin-bottom: 0;
  }
`;

export const HomeImpactSectionTitle = styled.h6`
  margin: 0;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.BLACK};
  padding-left: 30px;

  ${(props) =>
    props.left &&
    `
        padding-left: 60px;

        @media (max-width: ${BREAKPOINTS.TABLET}px) {
            padding-left: 30px;
        }
    `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 30px 0;
    text-transform: uppercase;
  }
`;

export const HomeImpactTag = styled.h5`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 15px;
  width: max-content;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 10px;
  background-color: ${COLORS.WHITE};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    margin-left: 15px;
    margin-top: -20px;
  }
`;

export const HomeImpactFeatureTitle = styled.h3`
  margin: 0;
  font-size: 34px;
  font-weight: normal;
  color: ${COLORS.BLACK};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 0 18px;
    font-size: 24px;
    font-weight: 500;
  }
`;

export const Description = styled.div`
  margin-bottom: 2px;
  text-decoration: none;
  color: ${COLORS.BLACK};
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.2px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-left: 20px;
  }
`;

export const HomeImpactFeatureP = styled.p`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: normal;
  color: ${COLORS.BLACK};
  max-width: 500px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    max-width: initial;
    padding: 0 20px;
    font-size: 16px;
  }
`;

export const HomeImpactFeatureControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    margin-bottom: 80px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    margin-bottom: 30px;
  }
`;

export const HomeImpactFeatureNextContainer = styled.div`
  display: flex;
  padding-left: 60px;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding-left: 30px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-left: 20px;
  }
`;

export const HomeImpactFeatureNextArrow = styled.img`
  width: 14px;
  height: 26px;
  margin-right: 20px;
`;

export const HomeImpactFeatureNext = styled.h6`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.BLACK};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-weight: 500;
  }
`;

export const HomeImpactFeatureStep = styled(HomeImpactFeatureNext)`
  padding-right: 30px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-right: 20px;
  }
`;

export const HomeImpactSectionContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  padding-left: 32px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding-left: 30px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const HomeImpactSectionCol = styled.div`
  height: ${SECTION_HEIGHT + 20}px;
  border-left: 1px solid ${COLORS.BLACK};
  width: 287px;
  margin-right: 20px;
  flex: none;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: calc(100% - 32px);
    margin: 0 16px;
    overflow: hidden;
    border-left: none;
    height: initial;

    &:nth-child(3) {
      display: none;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const HomeImpactSectionItem = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: row;
    margin-bottom: 20px;
    overflow: hidden;
    border-top: 1px solid ${COLORS.BLACK};
  }
`;

export const HomeImpactSectionItemImg = styled.div`
  position: relative;
  height: 158px;
  background-position: center;
  background-size: cover;

  ${(props) =>
    props.src &&
    `
        background-image: url(${props.src});
    `}

  ${(props) =>
    props.tall &&
    `
        height: 287px;
    `}

    ${(props) =>
    props.bigTall &&
    `
        height: 375px;
    `}

    @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 190px;
    height: 70px;

    ${(props) =>
      props.tall &&
      `
            width: 94px
        `}

    ${(props) =>
      props.bigTall &&
      `
            width: 94px
        `}
  }
`;

export const HomeImpactSectionItemTag = styled(HomeImpactTag)`
  position: absolute;
  left: 20px;
  bottom: -30px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    left: 0;
    bottom: initial;
    margin: 0;
    top: -10px;
  }
`;

export const HomeImpactSectionItemDesc = styled.h5`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.BLACK};
  padding: 45px 20px 50px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 16px;
  }
`;

export const HomeImpactSectionGoTo = styled.a`
  width: 800px;
  position: absolute;
  bottom: 335px;
  left: -325px;
  transform: rotate(-90deg);
  cursor: pointer;
  text-decoration: none;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    transform: none;
    position: initial;
    border-top: 1px solid;
    width: initial;
  }
`;

export const HomeImpactSectionGoToImpact = styled.h1`
  margin: 0;
  font-size: 104px;
  font-weight: 600;
  color: ${COLORS.WHITE};
  -webkit-text-stroke: 1px ${COLORS.BLACK};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 45px;
    text-align: center;
    padding: 40px 0;
  }
`;
